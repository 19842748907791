import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // withCredentials: true,
  //timeout: 2500
})

function setupInterceptors(history) {
  axiosInstance.interceptors.response.use(undefined, function (error) {

    if (error.response.status === 500) {
      //history.push('/maintain');
    }

    // if (error.response.status === 404) {
    //   history.push('/404');
    // }

    if (error.response.status === 401) {
      //history.push('/login');
    }

    return Promise.reject(error)
  })
}

export {
  setupInterceptors,
  axiosInstance,
}
