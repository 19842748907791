import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import { withRouter } from 'react-router-dom';
import CountrySelectDropdown from '../../../components/CountySelectDropdown/CountrySelectDropdown';
import calendarIcon from '../../../assets/images/calendar.png';
import locationIcon from '../../../assets/images/location.png';
import { IconButton } from '@material-ui/core';
import closeIcon from '../../../assets/icons/close.svg';

import style from './DentistRegister.module.scss';

const DentistRegister = props => {

  const [state, setState] = React.useState({
    name: '',
    birthday: '',
    gender: -1,
    country: -1,
    province: '',
    city: '',
    address: '',
    insurance: '',
    about: '',
    phone: '',
    photo: null,
    fax: '',
    zip: '',

    // consultant fields
    specialty: -1,
    language: -1,
    boardNumber: '',
    bankAccountInformation: '',

    inProgress: false,
    errors: {},
    touchedFields: []
  });

  const handleChange = e => {
    let newState = {};
    let touchedFields = [...state.touchedFields];

    if (!state.touchedFields.includes(e.target.name)) {
      touchedFields = [...state.touchedFields, e.target.name]
    }

    if ('checkbox' === e.target.type) {
      newState = {
        ...state,
        [e.target.name]: e.target.checked,
        touchedFields
      };
      setState(newState);
    } else {
      newState = {
        ...state,
        [e.target.name]: e.target.value,
        touchedFields
      }
      setState(newState);
    }
  }

  const validateForm = e => {

    let newState = { ...state }

    let errors = {}

    if (!state.name || state.name.length < 3) {
      errors.name = 'Please enter at least 3 characters';
    }

    if (!state.about || state.about.length < 3) {
      errors.about = 'Please add your bio';
    }
    if (!state.boardNumber) {
      errors.boardNumber = 'Please fill out this field';
    }
    if (!state.bankAccountInformation) {
      errors.bankAccountInformation = 'Please fill out this field';
    }
    if (!state.country || -1 === state.country) {
      errors.country = 'Please select your country';
    }
    if ('' === state.province) {
      errors.province = 'Please fill out this field';
    }
    if ('' === state.city) {
      errors.city = 'Please fill out this field';
    }
    if ('' === state.address) {
      errors.address = 'Please fill out this field';
    }
    if ('' === state.address) {
      errors.address = 'Please fill out this field';
    }
    if ('' === state.fax) {
      errors.fax = 'Please fill out this field';
    }
    if ('' === state.zip) {
      errors.zip = 'Please fill out this field';
    }

    let phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!state.phone || !phoneRegex.test(state.phone)) {
      errors.phone = 'Please enter a valid phone number';
    }

    // remove errors for non-touched field
    Object.keys(errors).forEach(err => {
      if (!newState.touchedFields.includes(err)) {
        delete errors[err]
      }
    })

    setState({ ...newState, errors })

    return errors;
  }

  const setPhoto = e => {
    setState({ ...state, photo: e.target.files[0] })
  }

  const submitDisabled = !state.photo ||
    14 > state.touchedFields.length ||
    state.inProgress ||
    Object.keys(state.errors).length > 0

  return (
    <>
      <Header color="dark" backButton loginButtons />
      <div className="ProfileCompletion">
        <div className="container pt-3 pb-5">
          <h1 className={style.heading}>Dentist Registration</h1>

          <TextField
            error={state.errors.firstName ? true : false}
            helperText={state.errors.firstName}
            onBlur={validateForm}
            inputProps={{ maxLength: 20 }}
            required
            // autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Full Legal Name"
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            onChange={handleChange}
          />

          <div className="mt-2 mb-0">
            <FormControl required variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                required
                labelId="gender"
                id="gender"
                name="gender"
                value={state.gender}
                onChange={handleChange}
              >
                <MenuItem value="-1" disabled>
                  <span className="text-tertiary"> Tap to select </span>
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>


          <TextField
            error={state.errors.insurance ? true : false}
            helperText={state.errors.insurance}
            onBlur={validateForm}
            required
            fullWidth
            // autoFocus
            margin="dense"
            id="insurance"
            name="insurance"
            label="Board Number"
            type="text"
            InputLabelProps={{ shrink: true, }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <div className="mt-2 mb-0">
            <FormControl required variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
              <InputLabel id="specialty">Specialty</InputLabel>
              <Select
                labelId="specialty"
                id="specialty"
                name="specialty"
                displayEmpty
                value={state.specialty}
                onChange={handleChange}
              >
                <MenuItem value="-1" disabled>
                  <span className="text-tertiary"> Tap to select </span>
                </MenuItem>
                <MenuItem value="endodontist">Endodontist</MenuItem>
                <MenuItem value="orthodontist">Orthodontist</MenuItem>
                <MenuItem value="pediatric dentist">Pediatric Dentist</MenuItem>
                <MenuItem value="periodontists">Periodontists</MenuItem>
                <MenuItem value="cosmetic dentist">Cosmetic Dentist</MenuItem>
              </Select>
            </FormControl>
          </div>

          <TextField
            error={state.errors.about ? true : false}
            helperText={state.errors.about}
            inputProps={{ minLength: 3, maxLength: 500 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="about"
            name="about"
            label="About"
            type="text"
            fullWidth
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            maxRows={4}
            variant="standard"
            onChange={handleChange}
          />

          <div className="mt-2 mb-0">
            <FormControl required variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
              <InputLabel id="language">Speaking Language</InputLabel>
              <Select
                labelId="language"
                id="language"
                name="language"
                displayEmpty
                value={state.language}
                onChange={handleChange}
              >
                <MenuItem value="-1" disabled>
                  <span className="text-tertiary"> Tap to select </span>
                </MenuItem>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">French</MenuItem>
                <MenuItem value="fa">Persian</MenuItem>
              </Select>
            </FormControl>
          </div>

          <TextField
            error={state.errors.phone ? true : false}
            helperText={state.errors.phone}
            onBlur={validateForm}
            required
            fullWidth
            // autoFocus
            margin="dense"
            id="phone"
            name="phone"
            label="Mobile Number"
            type="text"
            InputLabelProps={{ shrink: true, }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <TextField
            error={state.errors.fax ? true : false}
            helperText={state.errors.fax}
            onBlur={validateForm}
            required
            fullWidth
            // autoFocus
            margin="dense"
            id="fax"
            name="fax"
            label="Fax Number"
            type="text"
            InputLabelProps={{ shrink: true, }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />
          <TextField
            error={state.errors.bankAccountInformation ? true : false}
            helperText={state.errors.bankAccountInformation}
            onBlur={validateForm}
            required
            fullWidth
            // autoFocus
            margin="dense"
            id="bankAccountInformation"
            name="bankAccountInformation"
            label="Bank Account Number"
            type="text"
            InputLabelProps={{ shrink: true, }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <div className="mt-2 mb-0">
            <CountrySelectDropdown
              error={state.errors.country}
              value={state.country}
              onChange={handleChange} />
          </div>
          <TextField
            error={state.errors.province ? true : false}
            helperText={state.errors.province}
            inputProps={{ maxLength: 30, minLength: 2 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="province"
            name="province"
            label="Province"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <TextField
            error={state.errors.city ? true : false}
            helperText={state.errors.city}
            inputProps={{ maxLength: 30, minLength: 2 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="city"
            name="city"
            label="City"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <TextField
            error={state.errors.address ? true : false}
            helperText={state.errors.address}
            inputProps={{ maxLength: 500, minLength: 2 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="address"
            name="address"
            label="Address"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <TextField
            error={state.errors.zip ? true : false}
            helperText={state.errors.zip}
            inputProps={{ maxLength: 10, minLength: 10 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="zip"
            name="zip"
            label="Zip Code"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            variant="standard"
            onChange={handleChange}
          />

          <div className="mt-3 mb-3">
            <Button
              variant="contained"
              component="label"
              style={{
                borderRadius: '30px',
                textTransform: 'inherit'
              }}
            >
              {state.photo
                ? <>Change Profile Picture</>
                : <>Upload Profile Picture</>}
              <span aria-hidden="true" class="MuiFormLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span>
              <input
                type="file"
                accept="image/jpeg, image/png"
                hidden
                onChange={setPhoto}
              />
            </Button>

            {state.photo &&
              <>
                <span className="ml-2 mr-2">{state.photo.name}</span>
                <IconButton
                  style={{ background: 'lightgray' }}
                  onClick={e => { setState({ ...state, photo: null }) }}
                >
                  <img src={closeIcon} alt="close" width="15px" height="15px" />
                </IconButton>
              </>
            }
          </div>
          <div className="mt-4 mb-2 d-flex flex-center">
            <img src={locationIcon} alt="location" width="93px" />
            <p className="m-0 ml-3 text-tertiary">
              Tap to set your<br /> location
              <span aria-hidden="true" class="MuiFormLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span>
            </p>
          </div>
          <div className="mt-4 mb-2 d-flex flex-center">
            <img src={calendarIcon} alt="calendar" width="93px" />
            <p className="m-0 ml-3 text-tertiary">
              Tap to sync meetings to your calendar<br /> and Google meet platform
            </p>
          </div>
          <div className="mt-5 mb-5 text-right">
            <Button
              href="https://app.dentopia.ca"
              style={{
                fontSize: '24px',
                textTransform: 'inherit',
                color: submitDisabled ? '#aaa' : '#3DA3F7'
              }}
              color="primary"
              variant="link"
              disabled={submitDisabled}
            // onClick={submitForm}
            >
              {state.inProgress && <CircularProgress color="secondary" style={{ width: '16px', height: '16px' }} className="mr-2" />}
              Submit
              </Button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(DentistRegister);