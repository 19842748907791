const thousandSeparator = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const formatPrice = price => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price || 0)
}

const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
  return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + sizes[i];
}

const apiBaseUrl = url => url ? process.env.REACT_APP_API_BASE_URL + url : '';

const withCdnUrl = url => {
  
  if(!url) return '';

  if(process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_CDN_BASE_URL + url
  }

  return process.env.REACT_APP_PROD_CDN_BASE_URL + url
}

const isValidEmail = email => {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

const isValidPassword = password => {
  return password.length > 5;
}

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export {
  thousandSeparator,
  apiBaseUrl,
  withCdnUrl,
  bytesToSize,
  copyToClipboard,
  isValidEmail,
  isValidPassword,
  formatPrice
}
