import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import './Header.scss';
import logo from './logo.svg';
import logoDark from './logo-dark.svg';
import Login from '../Login/Login';
import { useUserContext } from '../../contexts/UserContext';

import backIcon from '../../assets/icons/arrow-left.svg';
import closeIcon from '../../assets/icons/close.svg';
import ForgetPass from '../ForgetPass/ForgetPass';

import queryString from 'query-string';
import {  isBrowser, isMobile,  } from 'react-device-detect';

const Header = (props) => {
  const { isAuthenticated, } = useUserContext();
  const invitationCode = queryString.parse(props.location.search).r || null;

  const [state, setState] = React.useState({
    popupOpen: invitationCode ? true : false,
    component: 'login',
    invitationCode: invitationCode || null
  })

  const [setAnchorEl] = React.useState(null)

 const closePopup = () => {
    setState({ ...state, popupOpen: false, invitationCode: null })
    setAnchorEl(null)
  }

  const openForget = () => setState({ ...state, component: 'forget' })
  const openLogin = () => setState({ ...state, component: 'login' })

  const back = () => {
    if (props.history.action !== 'POP') {
      props.history.goBack();
    } else {
      props.history.push('/')
    }
  }

  return (
    <header className={`header ${isMobile ? 'isMobile' : ''} ${'dark' === props.color ? 'dark' : ''}`}>
      <div className="headerInner">
        <div className="d-flex">
          {props.backButton &&
            <button className="backButton" onClick={back}>
              <img src={backIcon} alt="back" />
            </button>
          }
          <Link to="/" className="logoWrapper">
            {'dark' === props.color 
            ?<img src={logoDark} alt="dentopia" />
            :<img src={logo} alt="dentopia" />
            }
            
          </Link>
        </div>
        <div className="userArea">
          {props.loginButtons
            ? 
            <div class="loginButtons">
              <Button>Sign in</Button>
              <Button>Sign Up</Button>
            </div>
            : <Button className="appButton">Web Application</Button>
          }
        </div>
      </div>

      {!isAuthenticated &&
        <Dialog open={state.popupOpen}
          onClose={closePopup}
          className="authDialog"
          fullScreen={isMobile ? true : false}
        >
          {'login' === state.component &&
            <Login onSuccessLogin={closePopup} forget={openForget} invitationCode={state.invitationCode} />
          }
          {'forget' === state.component &&
            <ForgetPass onSuccessLogin={closePopup} login={openLogin} />
          }

          {!isBrowser &&
            <Button
              variant="contained"
              style={{
                position: 'absolute',
                bottom: '3rem',
                left: 'calc(50% - 30px)',
                width: '60px',
                height: '60px',
                padding: 0,
                lineHeight: '60px',
                borderRadius: '100%',
                margin: '0 auto',
                minWidth: '40px',
                background: 'white'
              }}
              onClick={closePopup} color="secondary">
              <img src={closeIcon} alt="close" width="20px" height="20px" />
            </Button>
          }
        </Dialog>
      }
    </header>
  );
};

export default withRouter(Header);