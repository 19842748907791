import {
  BrowserRouter as Router, Route, Switch,

} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import './App.scss';
import ThemeContext from "./contexts/themeContext";
import Home from "./scenes/Home/Home";
import { AuthProvider } from "./contexts/UserContext";
import ResetScroll from "./utils/ResetScroll";
import PatientRegister from "./scenes/register/Patient/PatientRegister";
import DentistRegister from "./scenes/register/Dentist/DentistRegister";
import LabRegister from "./scenes/register/Lab/LabRegister";

function App() {
  return (
    <Router>
      <ResetScroll>
        <AuthProvider>
          <ThemeContext>
            <Switch>
              <Route path="/register/patient" component={PatientRegister} />
              <Route path="/register/supplier" component={PatientRegister} />
              <Route path="/register/dentist" component={DentistRegister} />
              <Route path="/register/supplier" component={PatientRegister} />
              <Route path="/register/lab" component={LabRegister} />
              <Route path="/" component={Home} />
            </Switch>
            <Toaster position="bottom-center" />
          </ThemeContext>
        </AuthProvider>
      </ResetScroll>
    </Router>
  );
}

export default App;
