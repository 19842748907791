import React, { createContext, useContext } from 'react';
import { axiosInstance } from '../axiosConfig';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export default UserContext;

export class AuthProvider extends React.Component {

  state = {
    user: { id: 0 },
    newNotifications: 0,
    token: '',
    isAuthenticated: false,
    authConnectionProblem: false,
    loaded: true,
  }
  updatedSocket = false;

  componentDidMount() {

  }

  getNotifications(){
    axiosInstance.get('notifications').then(response => {
      this.setState({newNotifications: response.data.new})
    }).catch(() => {
      this.setState({ loaded: true })
    })
  }
  
  setUser = user => {
    this.setState({ user, loaded: true })
  }

  setAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }

  setNewNotificationCount = count => {
    this.setState({newNotifications: +count})
  }

  logout = () => {
    axiosInstance.delete('/account/logout').catch(err => { })
    this.setState({
      isAuthenticated: false,
      user: {}
    })
  }

  render() {
    return (
      <UserContext.Provider value={{
        user: this.state.user,
        setUser: this.setUser,
        isAuthenticated: this.state.isAuthenticated,
        setAuthenticated: this.setAuthenticated,
        newNotifications: this.state.newNotifications,
        setNewNotificationCount: this.setNewNotificationCount,
        loaded: this.state.loaded,
        connectError: this.state.authConnectionProblem,
        logout: this.logout
      }}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}


