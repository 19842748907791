import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { axiosInstance } from '../../axiosConfig';
import toast from 'react-hot-toast';
import { useUserContext } from '../../contexts/UserContext';
import { isValidEmail, isValidPassword } from '../../utils/common';
import { withRouter } from 'react-router-dom';

const ForgetPass = props => {
  const { setUser } = useUserContext();
  const [state, setState] = React.useState({
    step: 1,
    remember: true,
    email: '',
    emailValid: true,
    emailTouch: false,
    password: '',
    passwordValid: true,
    passwordTouch: false,
    confirm: '',
    type: null,
    error: '',
  });

  const handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = { ...state, [e.target.name]: e.target.checked };
      setState(newState);
    } else {
      newState = { ...state, [e.target.name]: e.target.value }
      setState(newState);
    }

    validateForm(newState)
  }

  const validateForm = newState => {
    let emailValid = true;
    let passwordValid = true;

    if (!isValidEmail(newState.email)) {
      emailValid = false
    }

    if (!isValidPassword(newState.password)) {
      passwordValid = false
    }

    setState({ ...newState, passwordValid, emailValid })
  }

  const errors = (response) => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('INVALID_VERIFICATION_CODE' === response.data.code) {
      toast.error('Invalid verification code')
    }
    if (401 === response.status) {
      toast.error('Invalid email or password')
    }
    if (409 === response.status) {
      toast.error('User already exists')
    }
  }

  const reset = event => {
    event.preventDefault();
    const userForm = new URLSearchParams();
    userForm.append('email', state.email);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    axiosInstance.post("/account/forgetPass", userForm, config)
      .then(result => {
        if (result.status === 200) {
          setState({ ...state, step: 2 })
        } else {
          setState({ ...state, isError: true })
        }
      }).catch(error => {
        errors(error.response)
      });
  }

  const login = event => {
    event.preventDefault();
    const userForm = new URLSearchParams();
    userForm.append('login', state.email);
    userForm.append('password', state.password);

    if (state.remember) {
      userForm.append('remember', state.remember);
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    axiosInstance.post("/account/login", userForm, config)
      .then(result => {
        if (result.status === 200) {
          setState({ ...state, isLoggedIn: true })
          setUser(result.data)
          props.onSuccessLogin()
        } else {
          setState({ ...state, isError: true })
        }
      }).catch(error => {
        errors(error.response)
      });
  }

  return (
    <>
      {1 === state.step &&
        <div className="p-5">
          <form onSubmit={reset}>
            <DialogTitle className="text-center mb-0">Forget Password</DialogTitle>
            <p className="p-0 m-0 mb-3 text-center">Fill in your email you registered with</p>
            <DialogContent style={{ maxWidth: '320px', margin: '0 auto' }}>
              <TextField
                error={!state.emailValid}
                // autoFocus
                margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                required
                InputLabelProps={{ shrink: true, }}
                placeholder="Email Address"
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                disabled={!state.email || !state.emailValid}
                variant="contained"
                style={{ borderRadius: '30px', boxShadow: 'none' }}
                onClick={reset}
              >Next</Button>

            </DialogActions>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button size="small" color="primary" onClick={props.login}>Back to Login</Button>
            </DialogActions>
          </form>
        </div>
      }

      {2 === state.step &&
        <div className="p-5">
          <DialogTitle className="text-center">Email Sent</DialogTitle>
          <p className="p-0 m-0 mt-1 text-center" style={{ fontSize: '20px' }}>We've sent you an email contains your password</p>
          <DialogContent style={{ maxWidth: '320px', margin: '0 auto' }}>
            <TextField
              error={!state.emailValid}
              // autoFocus
              margin="dense"
              value={state.email}
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              required
              InputLabelProps={{ shrink: true, }}
              placeholder="Email Address"
              onChange={handleChange}
            />
            <TextField
              error={!state.passwordValid}
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              required
              InputLabelProps={{ shrink: true, }}
              placeholder="Password"
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.remember}
                  onChange={handleChange}
                  name="remember"
                  color="primary"
                />
              }
              label="Remember me"
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              disabled={!state.email || !state.emailValid || !state.password || !state.passwordValid}
              variant="contained"
              style={{ borderRadius: '30px', boxShadow: 'none' }}
              onClick={login}
            >Sign In</Button>
          </DialogActions>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              size="small"
              color="primary"
              onClick={() => setState({ ...state, step: 1 })}
            >Didn't receive the email?</Button>
          </DialogActions>
        </div>
      }
    </>
  );
};

export default withRouter(ForgetPass);