import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import separator from '../../assets/images/footer-separator.png';

import style from './Footer.module.scss';

import facebook from '../../assets/icons/facebook.svg'
import twitter from '../../assets/icons/twitter.svg'
import linkedin from '../../assets/icons/linkedin.svg'
import instagram from '../../assets/icons/instagram.svg'
import { isMobileOnly as isMobile } from 'react-device-detect';

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className="container">
        {isMobile ?
          <div className="row">
            <div className="d-flex">
              <div className="left">
                <ContactUs />
                <QuickAccess />
              </div>
              <div className="right">
                <Social />
              </div>
            </div>
          </div>
          :
          <>
            <div className={`${style.primary} mt-5`}>
              <QuickAccess />
              <ContactUs />
              <img src={separator} alt="s" width="38" height="308" />
              <div className={`${style.about} ml-5 col-xs-12 col-md-3 col-md-6 col-lg-4`}>
                <Link to="/" className={style.logoWrapper}>
                  <img src={logo} alt="DENTOPIA" />
                </Link>
              </div>
            </div>
            <div className={`${style.secondary}`}>
              <Social />
              <p>© 2021 Dentopia Co. All rights reserved.</p>
            </div>
          </>
        }

      </div>
    </div>
  );
};

const Social = () => (
  <div className={`${style.widget}`}>
    <h3 className={`fw-normal ${!isMobile && 'text-center'}`}>Follow Us</h3>
    <div className={style.socialLinks}>
      <a href="http://#fb" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="facebook" />
        <span>Facebook</span>
      </a>
      <a href="http://#tw" target="_blank" rel="noopener noreferrer">
        <img src={twitter} alt="twitter" />
        <span>Twitter</span>
      </a>
      <a href="http://#in" target="_blank" rel="noopener noreferrer">
        <img src={linkedin} alt="linkedin" />
        <span>LinkedIn</span>
      </a>
      <a href="http://fb" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="instagram" />
        <span>Facebook</span>
      </a>
    </div>
  </div>
)

const ContactUs = () => (
  <div className={`${style.widget} ${style.contact} col-xs-12 col-sm-4 col-lg-3 mb-4`}>
    <h3 className="fw-normal">Contact Us</h3>

    <h5>Address</h5>
    <p>
      86-50 Burnhamthorpe Rd W
      Box 227
      Mississauga, ON L5B 3C2
    </p>

    <h5>Contact</h5>
    <p>
    Tel: +1-647-241-1848
    </p>

    <h5>Work Hours</h5>
    <p>Mon-Fri, 9:00-17:00</p>
  </div>

)

const QuickAccess = () => (
  <div className={`${style.widget} col-xs-12 col-sm-4 col-lg-2`}>
    <h3 className="fw-normal">Quick Access</h3>
    <ul>
      <li>
        <Link to="/">Homepage</Link>
      </li>
      <li>
        <Link to="/">Web App</Link>
      </li>
      <li>
        <Link to="/">Dentist</Link>
      </li>
      <li>
        <Link to="/">Labs</Link>
      </li>
      <li>
        <Link to="/">Contact Us</Link>
      </li>
    </ul>
  </div>

)

export default Footer;