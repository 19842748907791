import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import toast from 'react-hot-toast';
import { axiosInstance } from '../../../axiosConfig';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import { useUserContext } from '../../../contexts/UserContext';
import style from './PatientRegister.module.scss';
import { withRouter } from 'react-router-dom';
import CountrySelectDropdown from '../../../components/CountySelectDropdown/CountrySelectDropdown';
import { MobileDatePicker } from '@material-ui/lab';
import calendarIcon from '../../../assets/images/calendar.png';
import { IconButton } from '@material-ui/core';
import closeIcon from '../../../assets/icons/close.svg';

const PatientRegister = props => {

  const { user, setUser } = useUserContext();
  const [state, setState] = React.useState({
    name: '',
    birthday: '',
    gender: -1,
    country: -1,
    province: '',
    city: '',
    address: '',
    insurance: '',
    about: '',
    phone: '',
    photo: null,

    inProgress: false,
    errors: {},
    touchedFields: []
  });

  const handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = {
        ...state,
        [e.target.name]: e.target.checked,
        //touchedFields: [ ...state.touchedFields, e.target.name ]
      };
      setState(newState);
    } else {
      newState = {
        ...state,
        [e.target.name]: e.target.value,
        //touchedFields: [ ...state.touchedFields, e.target.name]
      }
      setState(newState);
    }
  }

  const validateForm = e => {

    let newState = {
      ...state,
      touchedFields: [...state.touchedFields, e.target.name, e.target.name]
    };
    setState(newState);

    let errors = {}

    if ('employer' === user.type) {
      if (!state.companyName || state.companyName.length < 3) {
        errors.companyName = 'Please enter at least 3 characters';
      }
      if (!state.companyRegistrationNumber) {
        errors.companyRegistrationNumber = 'Please fill out this field';
      }
      if ('' === state.companyEmployeesCount) {
        errors.companyEmployeesCount = 'Please enter a valid number';
      }
    }

    if (['consultant', 'freelancer'].includes(user.type)) {
      if (!state.firstName || state.firstName.length < 3) {
        errors.firstName = 'Please enter at least 3 characters';
      }
      if (!state.lastName || state.lastName.length < 3) {
        errors.lastName = 'Please enter at least 3 characters';
      }
    }

    if ('consultant' === user.type) {
      if (!state.bio || state.bio.length < 3) {
        errors.bio = 'Please add your bio';
      }
      if (!state.boardNumber) {
        errors.boardNumber = 'Please fill out this field';
      }
      if (!state.bankAccountInformation) {
        errors.bankAccountInformation = 'Please fill out this field';
      }
    }

    if (!state.country || -1 === state.country) {
      errors.country = 'Please select your country';
    }
    if ('' === state.province) {
      errors.province = 'Please fill out this field';
    }
    if ('' === state.city) {
      errors.city = 'Please fill out this field';
    }
    if ('' === state.address) {
      errors.address = 'Please fill out this field';
    }

    let phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!state.phone || !phoneRegex.test(state.phone)) {
      errors.phone = 'Please enter a valid phone number';
    }

    // remove errors for non-touched field
    Object.keys(errors).forEach(err => {
      if (!newState.touchedFields.includes(err)) {
        delete errors[err]
      }
    })

    setState({ ...newState, errors })
  }

  const setPhoto = e => {
    setState({ ...state, photo: e.target.files[0] })
  }

  const errors = (response) => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all required fields')
    }

    if (!response.data.code && 400 === response.status) {
      toast.error('Form fields are not valid')
    }
  }

  const updateProfile = event => {
    event.preventDefault();

    setState({ ...state, inProgress: true })
    const formData = new FormData();

    formData.append('country', state.country);
    formData.append('province', state.province);
    formData.append('city', state.city);
    formData.append('address', state.address);
    formData.append('photo', state.photo);
    formData.append('phone', state.phone);

    if (['consultant', 'freelancer', 'employee'].includes(user.type)) {
      formData.append('firstName', state.firstName);
      formData.append('lastName', state.lastName);
      formData.append('gender', state.gender);
    }

    if ('consultant' === user.type) {
      formData.append('bio', state.about);
      formData.append('specialties', [state.specialty]);
      formData.append('languages', [state.language]);
      formData.append('boardNumber', state.boardNumber);
      formData.append('bankAccountInformation', state.bankAccountInformation);
    }

    if ('employer' === user.type) {
      formData.append('companyName', state.companyName);
      formData.append('companyRegistrationNumber', state.companyRegistrationNumber);
      formData.append('companyEmployeesCount', state.companyEmployeesCount);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    axiosInstance.put("/users/n/complete-registration", formData, config)
      .then(response => {
        if (response.status === 200) {
          axiosInstance.get('user').then(response => {
            setUser(response.data)
          }).catch(err => { })
        }
        setState({ ...state, inProgress: false })
      }).catch(error => {
        errors(error.response)
        setState({ ...state, inProgress: false })
      })
  }

  return (
    <>
      <Header color="dark" backButton loginButtons />
      <div className="ProfileCompletion">
        <div className="container pt-3 pb-5">
          <h1 className={style.heading}>Patient Registration</h1>

          <TextField
            error={state.errors.firstName ? true : false}
            helperText={state.errors.firstName}
            onBlur={validateForm}
            inputProps={{ maxLength: 20 }}
            required
            // autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type Here"
            onChange={handleChange}
          />

          <div className="mt-2 mb-0">
            <FormControl required variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                required
                labelId="gender"
                id="gender"
                name="gender"
                value={state.gender}
                onChange={handleChange}
              >
                <MenuItem value="-1" disabled>
                  <span className="text-tertiary"> Tap to select </span>
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>

          <MobileDatePicker
            label="Birthday"
            value={state.birthday}
            onChange={(newValue) => {
              setState({ ...state, birthday: newValue })
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                error={state.errors.birthday ? true : false}
                variant="standard"
                InputLabelProps={{ shrink: true, }}
                placeholder="Tap to select"
                onBlur={validateForm}
                required
                fullWidth
              />
            }
          />

          <TextField
            error={state.errors.insurance ? true : false}
            helperText={state.errors.insurance}
            onBlur={validateForm}
            required
            fullWidth
            // autoFocus
            margin="dense"
            id="insurance"
            name="insurance"
            label="Insurance Number"
            type="text"
            InputLabelProps={{ shrink: true, }}
            placeholder="Tap to edit"
            variant="standard"
            onChange={handleChange}
          />

          <TextField
            error={state.errors.phone ? true : false}
            helperText={state.errors.phone}
            onBlur={validateForm}
            required
            fullWidth
            // autoFocus
            margin="dense"
            id="phone"
            name="phone"
            label="Mobile Number"
            type="text"
            InputLabelProps={{ shrink: true, }}
            placeholder="Tap to edit"
            variant="standard"
            onChange={handleChange}
          />


          <div className="mt-2 mb-0">
            <CountrySelectDropdown
              error={state.errors.country}
              value={state.country}
              onChange={handleChange} />
          </div>

          <TextField
            error={state.errors.province ? true : false}
            helperText={state.errors.province}
            inputProps={{ maxLength: 30, minLength: 2 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="province"
            name="province"
            label="Province"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Tap to edit"
            variant="standard"
            onChange={handleChange}
          />

          <TextField
            error={state.errors.city ? true : false}
            helperText={state.errors.city}
            inputProps={{ maxLength: 30, minLength: 2 }}
            onBlur={validateForm}
            required
            // autoFocus
            margin="dense"
            id="city"
            name="city"
            label="City"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Tap to edit"
            variant="standard"
            onChange={handleChange}
          />

          <div className="mt-3 mb-3">
            <Button
              variant="contained"
              component="label"
              style={{
                borderRadius: '30px',
                textTransform: 'inherit'
              }}
            >
              {state.photo
                ? <>Change Profile Picture</>
                : <>Upload Profile Picture</>}
              <input
                type="file"
                accept="image/jpeg, image/png"
                hidden
                onChange={setPhoto}
              />
            </Button>

            {state.photo &&
              <>
                <span className="ml-2 mr-2">{state.photo.name}</span>
                <IconButton
                  style={{ background: 'lightgray' }}
                  onClick={e => { setState({ ...state, photo: null }) }}
                >
                  <img src={closeIcon} alt="close" width="15px" height="15px" />
                </IconButton>
              </>
            }
          </div>

          <div className="mt-4 mb-2 d-flex flex-center">
            <img src={calendarIcon} alt="calendar" width="93px" />
            <p className="m-0 ml-3 text-tertiary">
              Tap to sync meetings to your calendar<br /> and Google meet platform
            </p>
          </div>
          <div className="mt-5 mb-5 text-right">
            <Button
              href="https://app.dentopia.ca"
              style={{
                fontSize: '24px',
                textTransform: 'inherit',
                color: '#3DA3F7'
              }}
              color="primary"
              variant="link"
              disabled={state.inProgress || Object.keys(state.errors).length > 0}
              // onClick={updateProfile}
            >
              {state.inProgress && <CircularProgress color="secondary" style={{ width: '16px', height: '16px' }} className="mr-2" />}
              Submit
              </Button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(PatientRegister);