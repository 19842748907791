import React from 'react';
import Header from '../../components/Header/Header';
import style from './Home.module.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import Chiropractic from '../../assets/mock/Chiropractic.png'
// import Kinesiology from '../../assets/mock/Kinesiology.png'
// import Physiotherapy from '../../assets/mock/Physiotherapy.png'
// import Gynecology from '../../assets/mock/Gynecology.png'
// import Psychology from '../../assets/mock/Psychology.png'
// import Nutrition from '../../assets/mock/Nutrition.png'

// import coverFeature1 from '../../assets/images/Group.png';
import coverFeature2 from '../../assets/images/Group(1).png';
import coverFeature3 from '../../assets/images/Group(2).png';
// import coverFeature4 from '../../assets/images/Group(3).png';

import dentist from '../../assets/images/dentist.png';
import supplier from '../../assets/images/supplier.png';
import lab from '../../assets/images/lab.png';

import Footer from '../../components/Footer/Footer';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';

const Home = props => {
  React.useEffect(() => {
    document.title = "Dentopia";
  }, [])

  return (
    <>
      <Header />
      <div className="Home">
        <div className={style.heroOuter}>
          <div className={style.hero}>
            <div className="container">
              <div className={style.heroContent}>
                <h1>Plenary Dental Platform<br /> for</h1>
                <div className="features d-flex flex-c-center p-3 mt-4">
                  {/* <div className="item ml-3 mr-3">
                    <img src={coverFeature1} alt="feature" />
                    <label className="label">Patients</label>
                  </div> */}
                  <div className="item ml-3 mr-3">
                    <img src={coverFeature2} alt="feature" />
                    <label className="label">Dentists</label>
                  </div>
                  <div className="item ml-3 mr-3">
                    <img src={coverFeature3} alt="feature" />
                    <label className="label">Dental Labs</label>
                  </div>
                  {/* <div className="item ml-3 mr-3">
                    <img src={coverFeature4} alt="feature" />
                    <label className="label">Suppliers</label>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${style.about} mt-5 pt-5 pb-5`}>
          <div className="container">
            <h2>
              <span style={{}}>What is</span>
              <span style={{}}>Dentopia</span>
            </h2>
            <div className="row mt-1">
              <div className="col-xs-12 col-sm-6">
                <p className="text-tertiary" style={{ fontSize: '23px', lineHeight: '32px' }}>
                  Dentopia is a comprehensive platform that connects Dentists to Dental Labs.
                </p>
                <p className="fg-primary mt-2 mb-3 fs-p1">
                  Problems existing in the relationship between Dentists and Dental Labs
                </p>
                <p className="mt-2 mb-1">
                  Nearly 40% of dentists’ revenue comes from bridge work and fixed crowns crafted by laboratories. However, in the last two years, 33% of dentists have switched labs suggesting that access to consistent quality lab services is an issue.
                </p>
                <p className="mt-3 mb-1">
                  <div className="mb-2">
                    (1) Difficulty identifying the right laboratory
                  </div>
                  Trial and error or word of mouth are typically how dentists will find new dental laboratories. There are no credible, objective, or comprehensive rating system available to give dentists the required comfort to engage with a new laboratory.
                  Dentopia will provide a credible system of information regarding each dental laboratory, so that the dentist can make an informed choice as to where to place the required orders.
                </p>
                <p className="mt-3 mb-1">
                  (2) Inefficient communication negatively impacts dentists and dental labs, alike
                  Incomplete and incorrect work orders result in delays, increased costs, and inefficient use of resources.
                  Dentopia will establish a standard set of parameters to eliminate, or drastically reduce, incomplete and incorrect work orders.
                </p>
              </div>
              <div className="col-xs-12 col-sm-6">
                <p className="fg-primary mt-2 mb-0 fs-p1">
                  Our response to problems
                </p>
                <p className="mt-3 mb-4">
                  <div className="mb-2">
                    (1) Rating
                  </div>
                  A platform that can rate labs by utilizing trustworthy algorithm and processes that track, evaluate and compare performance of any lab based on a set of detailed variables, that affect the service and quality of products being offered by the lab;
                </p>
                <p className="mt-4 mb-4">
                  <div className="mb-2">
                    (2) Communication
                  </div>
                  The AI-empowered algorithm continuously supervises communications between labs and dentists, ensuring that the communicational streams between the two are effective;
                </p>
                <p className="mt-4 mb-1">
                  <div className="mb-2">
                    (3) Identification
                  </div>
                  The AI-powered algorithm will identify overtime failures related to the dentists and labs that use the application. It will be able to identify both industry-spread communicational failures, or those of specific dental practices and dental labs and propose pro-active solutions to these specific and identifiable failures.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={`${style.services} pt-5 pb-5`}>
          <div className="container">
            <div className="row">
              <div className="col-xs-7">
                <h2>
                  <span style={{}}>What a</span>
                  <span style={{}}>Patient</span>
                  <span style={{}}>Can Get</span>
                </h2>
                <p className="mt-4 mb-1">Dentopia is a comprehensive platform for you as a patient through which you can access a complete set of dental services.</p>
                <p className="mt-1 mb-1">Through this platform, you can search for dentists in six different services, filter them based on different criteria such as technology used, speaking language, gender, etc. You can check dentist rate and other users' reviews to have a good choice. After selecting the desired person, you can book a meeting with the dentist through your panel.</p>
                <p className="mt-1 mb-1">After the visit, if you need further processes such as constructions (which your dentist orders from the laboratory), you can track these processes right through your user panel.</p>
              </div>
              <div className="col-xs-5">
                <div className="row mt-3">
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <Link to="/therapists?spec=cosmetic dentist" className={style.service}>
                      <img src={Chiropractic} alt="Cosmetic Dentistry" />
                      <h3 className={style.serviceTitle}> Cosmetic Dentistry</h3>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <Link to="/therapists?spec=orthodontist" className={style.service}>
                      <img src={Gynecology} alt="Orthodontic Services" />
                      <h3 className={style.serviceTitle}> Orthodontic Services </h3>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <Link to="/therapists?spec=endodontist" className={style.service}>
                      <img src={Kinesiology} alt="Endodontic Procedures" />
                      <h3 className={style.serviceTitle}> Endodontic Procedures </h3>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <Link to="/therapists?spec=periodontists" className={style.service}>
                      <img src={Psychology} alt=" Periodontal Treatments" />
                      <h3 className={style.serviceTitle}> Periodontal Treatments </h3>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <Link to="/therapists?spec=pediatric dentist" className={style.service}>
                      <img src={Physiotherapy} alt="Pediatric Dental Services" />
                      <h3 className={style.serviceTitle}> Pediatric Dental Services </h3>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <Link to="therapists?spec=prosthodontics-services" className={style.service}>
                      <img src={Nutrition} alt="Prosthodontics Services" />
                      <h3 className={style.serviceTitle}> Prosthodontics Services </h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.register}>
              <span className={style.registerSeparator}></span>
              <h4 className={style.registerTitle}>Register as a Patiens</h4>
              <Button component={Link} to="/register/patient">Register</Button>
            </div>
          </div>
        </div> */}

        <div className={`${style.dentistCta} pt-5 pb-5 mt-5`}>
          <div className="container">
            <div className="row">
              <div className="col-xs-4">
                <img src={dentist} alt="dentist" width="389" height="auto" />
              </div>
              <div className="col-xs-8">
                <h2>
                  <span style={{}}>How a</span>
                  <span style={{}}>Dentist</span>
                  <span style={{}}>Can Benefit</span>
                </h2>
                <p className="mt-4 mb-1">
                  As a dentist, you can manage all lab-related processes together in your Dentopia panel. In fact, you can see a complete list of laboratories, and filter according to your needs in terms of price, technologies used, quality of materials, etc. Then you can read the comments and ratings of other dentists and find the best lab for cooperation and send your order to the lab.
                </p>
              </div>
            </div>
            <div className={style.register}>
              <span className={style.registerSeparator}></span>
              <h4 className={style.registerTitle}>Register as a Dentist</h4>
              <Button component={Link} to="/register/dentist">Register</Button>
            </div>
          </div>
        </div>

        <div className={`${style.labsCta} pt-5 pb-5 mt-5`}>
          <div className="container">

            <h2>
              <span style={{}}>What about</span>
              <span style={{}}>Laboratories</span>
            </h2>
            <img src={lab} alt="lab" />
            <p className="mt-4 mb-1">As a laboratory, you can manage all dentist-related order processes together in your Dentopia panel.</p>
            <p className="mt-1 mb-1">In addition, by registering in Dentopia, your lab will be seen by all dentists that are using the portal. In addition, our AI-powered algorithm will also provide you with your rating on the platform AND provide ongoing suggestions as to how you can improve your rating and set yourself apart from your peers.  </p>
          </div>

          <div className={style.register}>
            <span className={style.registerSeparator}></span>
            <h4 className={style.registerTitle}>Register as a Lab</h4>
            <Button component={Link} to="/register/lab">Register</Button>
          </div>
        </div>

        <div className={`${style.supplierCta} pt-5 pb-5 mt-5 d-none`}>
          <div className="container">
            <div className="row">
              <div className="col-xs-8">
                <h2>
                  <span style={{}}>If you are a</span>
                  <span style={{}}>Dental</span>
                  <span style={{}}>Supplier</span>
                </h2>
                <p className="mt-4 mb-1">As a dental supplier, you can access a comprehensive list of laboratories. Also, by registering in Dentopia, you can create your own store and display your goods to be seen by these laboratories.</p>
                <p className="mt-1 mb-1">Also, you can manage all the steps of selling products until sending and receiving receivables through your panel.</p>
              </div>
              <div className="col-xs-4">
                <img src={supplier} alt="supplier" width="300" height="auto" />
              </div>
            </div>

            <div className={style.register}>
              <span className={style.registerSeparator}></span>
              <h4 className={style.registerTitle}>Register as a Dental<br /> Supplier</h4>
              <Button component={Link} to="#">Register</Button>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default withRouter(Home);