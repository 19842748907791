import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3DA3F7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#434343',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.625rem',
    },
  },
})

export default function ThemeContext(props) {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  );
}